<template>
    <div class="wrapper">
        <NavBar />
        <Menu />
        <!-- Spinner Start -->
        <div id="spinner" v-if="halamanloading"
            class="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
            <div class="spinner-border text-danger" style="width: 3rem; height: 3rem;" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
        <!-- Spinner End -->
        <div class="content-wrapper py-5">
            <div class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-6 wow fadeInDown">
                            <h5>Pengaturan</h5>
                        </div>
                        <div class="col-sm-6 wow fadeInDown">
                            <ol class="breadcrumb float-sm-right">
                                <li class="breadcrumb-item"><a href="/Dashboard">Home </a></li>
                                <li class="breadcrumb-item"><a href="/LPJUAdmin">Data LPJU</a></li>
                                <li class="breadcrumb-item active">Pengaturan Data LPJU</li>
                            </ol>
                        </div>
                    </div><!-- /.row -->
                </div><!-- /.container-fluid -->
            </div>
            <!-- /.content-header -->
            <div class="content">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-sm-12 wow fadeInDown">
                            <div class="card cardputih2">
                                <div class="card-header border-transparent">
                                    <h3 class="card-title">Form Input Data LPJU</h3>
                                    <div class="card-tools">
                                        <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                            <i class="fas fa-minus"></i>
                                        </button>
                                    </div>
                                </div>
                                <div class="card-body p-3">
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <label>Nama</label>
                                            <div class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i
                                                            class="fab fa-creative-commons-share"></i></span>
                                                </div>
                                                <input type="text" class="form-control form-control-sm" id="NAMA"
                                                    name="NAMA" placeholder="Nama" v-model="NAMA">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <label>ID LPJU</label>
                                            <div class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i
                                                            class="fab fa-creative-commons-share"></i></span>
                                                </div>
                                                <input type="text" class="form-control form-control-sm" id="IDLPJU"
                                                    name="IDLPJU" placeholder="ID LPJU" v-model="IDLPJU">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <label>ID APP</label>
                                            <div class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i
                                                            class="fab fa-creative-commons-share"></i></span>
                                                </div>
                                                <input type="text" class="form-control form-control-sm" id="ID_APP"
                                                    name="ID_APP" placeholder="ID APP" v-model="ID_APP">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <label>Jenis Legalitas</label>
                                            <v-select :options="LEGAL" :reduce="(label) => label.code" label="label"
                                                v-model="defaultSelectedLEGAL" @update:modelValue="onLEGALChange"
                                                style="background-color: #ffffff;padding-bottom: 10px;"></v-select>
                                        </div>
                                        <div class="col-sm-6">
                                            <label>Nomor Tiang</label>
                                            <div class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i
                                                            class="fab fa-creative-commons-share"></i></span>
                                                </div>
                                                <input type="text" class="form-control form-control-sm" id="NO_TIANG_P"
                                                    name="NO_TIANG_P" placeholder="Nomor Tiang" v-model="NO_TIANG_P">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <label>Tahun</label>
                                            <div class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i
                                                            class="fab fa-creative-commons-share"></i></span>
                                                </div>
                                                <input type="number" class="form-control form-control-sm" id="TAHUN"
                                                    name="TAHUN" placeholder="Tahun" v-model="TAHUN">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <label>Latitude</label>
                                            <div class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i
                                                            class="fab fa-creative-commons-share"></i></span>
                                                </div>
                                                <input type="number" class="form-control form-control-sm" id="LATITUDE"
                                                    name="LATITUDE" placeholder="Latitude" v-model="LATITUDE">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <label>Longitude</label>
                                            <div class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i
                                                            class="fab fa-creative-commons-share"></i></span>
                                                </div>
                                                <input type="number" class="form-control form-control-sm" id="LONGITUDE"
                                                    name="LONGITUDE" placeholder="Longitude" v-model="LONGITUDE">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <label>Jenis</label>
                                            <div class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i
                                                            class="fab fa-creative-commons-share"></i></span>
                                                </div>
                                                <input type="text" class="form-control form-control-sm" id="JENIS"
                                                    name="JENIS" placeholder="Jenis" v-model="JENIS">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <label>Tipe Lampu</label>
                                            <div class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i
                                                            class="fab fa-creative-commons-share"></i></span>
                                                </div>
                                                <input type="text" class="form-control form-control-sm" id="TIPELAMPU"
                                                    name="TIPELAMPU" placeholder="Tipe Lampu" v-model="TIPELAMPU">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <label>Watt Lampu</label>
                                            <div class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i
                                                            class="fab fa-creative-commons-share"></i></span>
                                                </div>
                                                <input type="number" class="form-control form-control-sm" id="WATT"
                                                    name="WATT" placeholder="Watt Lampu" v-model="WATT">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <label>Rumah Lampu</label>
                                            <div class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i
                                                            class="fab fa-creative-commons-share"></i></span>
                                                </div>
                                                <input type="text" class="form-control form-control-sm" id="RUMAHLAMPU"
                                                    name="RUMAHLAMPU" placeholder="Rumah Lampu" v-model="RUMAHLAMPU">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <label>Kondisi Lampu</label>
                                            <v-select :options="KONDISI_LP" :reduce="(label) => label.code"
                                                label="label" v-model="defaultSelectedKONDISI_LP"
                                                @update:modelValue="onKONDISI_LPChange"
                                                style="background-color: #ffffff;padding-bottom: 10px;"></v-select>
                                        </div>
                                        <div class="col-sm-6">
                                            <label>Panjang Tiang Lampu (m)</label>
                                            <div class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i
                                                            class="fab fa-creative-commons-share"></i></span>
                                                </div>
                                                <input type="number" class="form-control form-control-sm" id="PANJANG"
                                                    name="PANJANG" placeholder="Panjang Tiang Lampu (m)"
                                                    v-model="PANJANG">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <label>Kewenangan Jalan</label>
                                            <v-select :options="JALAN" :reduce="(label) => label.code" label="label"
                                                v-model="defaultSelectedJALAN" @update:modelValue="onJALANChange"
                                                style="background-color: #ffffff;padding-bottom: 10px;"></v-select>
                                        </div>
                                        <div class="col-sm-6">
                                            <label>Nama Jalan</label>
                                            <div class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i
                                                            class="fab fa-creative-commons-share"></i></span>
                                                </div>
                                                <input type="text" class="form-control form-control-sm" id="NAMA_JALAN"
                                                    name="NAMA_JALAN" placeholder="Nama Jalan" v-model="NAMA_JALAN">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <label>Kondisi LPJU</label>
                                            <v-select :options="KONDISI" :reduce="(label) => label.code" label="label"
                                                v-model="defaultSelectedKONDISI" @update:modelValue="onKONDISIChange"
                                                style="background-color: #ffffff;padding-bottom: 10px;"></v-select>
                                        </div>
                                        <div class="col-sm-6">
                                            <label>Kewenangan LPJU</label>
                                            <v-select :options="KEWENANGAN" :reduce="(label) => label.code"
                                                label="label" v-model="defaultSelectedKEWENANGAN"
                                                @update:modelValue="onKEWENANGANChange"
                                                style="background-color: #ffffff;padding-bottom: 10px;"></v-select>
                                        </div>
                                        <div class="col-sm-6">
                                            <label>Kecamatan</label>
                                            <v-select :options="KECAMATAN" :reduce="(label) => label.code" label="label"
                                                v-model="defaultSelectedKECAMATAN"
                                                @update:modelValue="onKECAMATANChange"
                                                style="background-color: #ffffff;padding-bottom: 10px;"></v-select>
                                        </div>
                                        <div class="col-sm-6">
                                            <label>Kelurahan</label>
                                            <v-select :options="KELURAHAN" :reduce="(label) => label.code" label="label"
                                                v-model="defaultSelectedKELURAHAN"
                                                @update:modelValue="onKELURAHANChange"
                                                style="background-color: #ffffff;padding-bottom: 10px;"></v-select>
                                        </div>
                                        <!--IKON PETA 1-->
                                        <div class="col-sm-12">
                                            <label>Dokumentasi LPJU<small class="text-info"> (*jpg)</small>
                                            </label>
                                            <div class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i
                                                            class="far fa-file-image"></i></span>
                                                </div>
                                                <input type="file" class="form-control form-control-sm"
                                                    id="fileToUploadfilenya_data" name="fileToUploadfilenya_data"
                                                    placeholder="fileToUpload" style="font-size: smaller;"
                                                    accept="image/*">
                                                <input type="hidden" class="form-control form-control-sm"
                                                    id="filenya_data" name="filenya_data" placeholder="File">
                                            </div>
                                        </div>
                                        <div class="col-sm-12">
                                            <label>Opsi</label>
                                            <div class="row">
                                                <div class="col-sm-9">
                                                    <div class="progress">
                                                        <div class="progress-bar" role="progressbar" aria-valuenow="0"
                                                            aria-valuemin="0" aria-valuemax="100" style="width:0%"
                                                            id="persennyafilenya_data">
                                                            <span id="tulisanpersenfilenya_data">0%</span>
                                                        </div>
                                                    </div>
                                                    <br>
                                                    <span class="text-success" id="suksesnyafilenya_data"></span>
                                                </div>
                                                <div class="col-sm-3 text-center">
                                                    <button type="button"
                                                        class="btn btn-warning btn-sm lebar text-white"
                                                        @click="uploadfile('filenya_data', 'image/*')">Upload
                                                        File</button>
                                                    <button type="button" class="btn btn-danger btn-sm lebar"
                                                        @click="clearfilepeta('filenya_data')">Hapus File</button>
                                                </div>
                                            </div>
                                        </div>
                                        <!--IKON PETA 1 END-->
                                        <div class="col-sm-12">&nbsp;</div>
                                        <div class="col-sm-12">
                                            <label>Keterangan</label>
                                            <div class="row">
                                                <div class="col-sm-12">
                                                    <quill-editor v-model:value="KETERANGAN"
                                                        style="height: 25vh;"></quill-editor>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12">&nbsp;</div>
                                        <div class="col-sm-12">&nbsp;</div>
                                    </div>
                                </div>
                                <div class="card-footer p-3">
                                    <button type="button" class="btn btn-sm btn-success3 float-left text-white lebar2"
                                        @click="simpan()" v-if="(secretencData == 'Tambah')" data-toggle="tooltip"
                                        data-placement="left" title="Simpan data.."><i class="fas fa-plus-square"></i>
                                        Simpan</button>
                                    <button type="button" class="btn btn-sm btn-success3 float-left text-white lebar2"
                                        v-if="(secretencData != 'Tambah')" @click="update()" data-toggle="tooltip"
                                        data-placement="left" title="Update data.."><i class="fa fa-check-circle"></i>
                                        Update</button>
                                    <button type="button" class="btn btn-sm btn-danger3 float-left text-white lebar2"
                                        @click="batalproses()" data-toggle="tooltip" data-placement="left"
                                        title="Batalkan proses.."><i class="fas fa-window-close"></i> Batal</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import $ from 'jquery';
import axios from 'axios';
import vSelect from 'vue-select';
import swal from 'sweetalert2';
import CryptoJS from 'crypto-js';
import { useRoute } from 'vue-router';
import { watch } from 'vue';
import { quillEditor } from 'vue3-quill';
import Footer from "../../componen/Footer.vue";
import NavBar from "../../componen/NavBar.vue";
import Menu from "../../componen/Menu.vue";

import VueApexCharts from "vue3-apexcharts";
window.Apex.chart = { fontFamily: "Poppins-SemiBold, Arial, sans-serif" };
export default {
    setup() {
        const route = useRoute()
        watch(() => route.name, () => {
        });

        return { route };
    },
    components: {
        Footer, NavBar, Menu, vSelect, swal, quillEditor
    },
    data() {
        return {
            halamanloading: true,
            iduser: JSON.parse(localStorage.getItem("usistem")),
            leveluser: JSON.parse(localStorage.getItem("lsistem")),
            namauser: JSON.parse(localStorage.getItem("nmusistem")),
            secretencData: decodeURIComponent(CryptoJS.AES.decrypt(this.route.params.id, 'oriproMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2').toString(CryptoJS.enc.Utf8)),
            datapesan: '',
            NAMA: '',
            IDLPJU: '',
            NO_TIANG_P: '',
            LATITUDE: '',
            LONGITUDE: '',
            JENIS: '',
            TIPELAMPU: '',
            WATT: '',
            RUMAHLAMPU: '',
            PANJANG: '',
            JALAN: [
                {
                    code: 0,
                    label: 'Pilih Salah Satu',
                    tag: '',
                }, {
                    code: 1,
                    label: 'NASIONAL',
                    tag: 'NASIONAL',
                },
                {
                    code: 2,
                    label: 'PROVINSI',
                    tag: 'PROVINSI',
                },
                {
                    code: 3,
                    label: 'KABUPATEN',
                    tag: 'KABUPATEN',
                },
                {
                    code: 4,
                    label: 'DESA',
                    tag: 'DESA',
                },
                {
                    code: 5,
                    label: 'LINGKUNGAN',
                    tag: 'LINGKUNGAN',
                },
            ],
            dataJALAN: [],
            defaultSelectedJALAN: {
                code: 0,
                label: 'Pilih Salah Satu',
                tag: '',
            },
            selectedJALAN: '',

            KONDISI_LP: [
                {
                    code: 0,
                    label: 'Pilih Salah Satu',
                    tag: '',
                }, {
                    code: 1,
                    label: 'BAIK',
                    tag: 'BAIK',
                },
                {
                    code: 2,
                    label: 'SEDANG',
                    tag: 'SEDANG',
                },
                {
                    code: 3,
                    label: 'RUSAK',
                    tag: 'RUSAK',
                },
                {
                    code: 4,
                    label: 'HILANG',
                    tag: 'HILANG',
                },
            ],
            dataKONDISI_LP: [],
            defaultSelectedKONDISI_LP: {
                code: 0,
                label: 'Pilih Salah Satu',
                tag: '',
            },
            selectedKONDISI_LP: '',

            KEBUTUHAN: '',
            ID_APP: '',
            PROVINSI: '',
            KAB_KOTA: '',
            KELURAHAN: [],
            dataKELURAHAN: [],
            defaultSelectedKELURAHAN: {
                code: 0,
                label: 'Pilih Salah Satu',
                tag: '',
            },
            selectedKELURAHAN: '',

            KECAMATAN: [],
            dataKECAMATAN: [],
            defaultSelectedKECAMATAN: {
                code: 0,
                label: 'Pilih Salah Satu',
                tag: '',
            },
            selectedKECAMATAN: '',

            NAMA_JALAN: '',

            LEGAL: [
                {
                    code: 0,
                    label: 'Pilih Salah Satu',
                    tag: '',
                }, {
                    code: 1,
                    label: 'TIDAK TERMETER',
                    tag: 'TIDAK TERMETER',
                },
                {
                    code: 2,
                    label: 'TERMETER',
                    tag: 'TERMETER',
                },
            ],
            dataLEGAL: [],
            defaultSelectedLEGAL: {
                code: 0,
                label: 'Pilih Salah Satu',
                tag: '',
            },
            selectedLEGAL: '',

            KONDISI: [
                {
                    code: 0,
                    label: 'Pilih Salah Satu',
                    tag: '',
                }, {
                    code: 1,
                    label: 'BAIK',
                    tag: 'BAIK',
                },
                {
                    code: 2,
                    label: 'SEDANG',
                    tag: 'SEDANG',
                },
                {
                    code: 3,
                    label: 'RUSAK',
                    tag: 'RUSAK',
                },
            ],
            dataKONDISI: [],
            defaultSelectedKONDISI: {
                code: 0,
                label: 'Pilih Salah Satu',
                tag: '',
            },
            selectedKONDISI: '',

            KEWENANGAN: [
                {
                    code: 0,
                    label: 'Pilih Salah Satu',
                    tag: '',
                }, {
                    code: 1,
                    label: 'NASIONAL',
                    tag: 'NASIONAL',
                },
                {
                    code: 2,
                    label: 'PROVINSI',
                    tag: 'PROVINSI',
                },
                {
                    code: 3,
                    label: 'KABUPATEN',
                    tag: 'KABUPATEN',
                },
                {
                    code: 4,
                    label: 'DESA',
                    tag: 'DESA',
                },
                {
                    code: 5,
                    label: 'LINGKUNGAN',
                    tag: 'LINGKUNGAN',
                },
            ],
            dataKEWENANGAN: [],
            defaultSelectedKEWENANGAN: {
                code: 0,
                label: 'Pilih Salah Satu',
                tag: '',
            },
            selectedKEWENANGAN: '',

            KETERANGAN: '',
            TAHUN: '',
            filenya_data: '',
            validasi: false,
        }
    },
    methods: {
        onJALANChange(a) {
            try {
                this.selectedJALAN = this.JALAN[a].tag;
            }
            catch (err) {
                return;
            }
        },
        onKEWENANGANChange(a) {
            try {
                this.selectedKEWENANGAN = this.KEWENANGAN[a].tag;
            }
            catch (err) {
                return;
            }
        },
        onKONDISI_LPChange(a) {
            try {
                this.selectedKONDISI_LP = this.KONDISI_LP[a].tag;
            }
            catch (err) {
                return;
            }
        },
        onLEGALChange(a) {
            try {
                this.selectedLEGAL = this.LEGAL[a].tag;
            }
            catch (err) {
                return;
            }
        },
        onKONDISIChange(a) {
            try {
                this.selectedKONDISI = this.KONDISI[a].tag;
            }
            catch (err) {
                return;
            }
        },
        onKECAMATANChange(a) {
            try {
                this.selectedKECAMATAN = this.KECAMATAN[a].tag;
            }
            catch (err) {
                return;
            }
            this.ambildataKELURAHAN(this.selectedKECAMATAN);
        },
        onKELURAHANChange(a) {
            try {
                this.selectedKELURAHAN = this.KELURAHAN[a].tag;
            }
            catch (err) {
                return;
            }
        },
        initialize() {
            //Initialize Select2 Elements
            $(".select2").select2();

            //Initialize Select2 Elements
            $(".select2bs4").select2({
                theme: "bootstrap4",
            });
            $('[data-mask]').inputmask();
            //$('#summernote').summernote();
        },
        getOtentifikasi() {
            var time = Date.parse(localStorage.getItem("tsistem"));
            var timeakhir = time + (3 * 60 * 60 * 1000);
            var timesekarang = Date.parse(new Date());
            if (timesekarang > timeakhir) {
                this.ClearlocalStorage();
            }
            this.halamanloading = false;
        },
        ontabelChange(a) {
            try {
                this.selectedtabel = this.tabel[a].tag;
            }
            catch (err) {
                return;
            }
        },
        batalproses() {
            window.location.replace("/LPJUAdmin");
        },
        uploadfile(idnya, tipenya) {
            var elmnt = document.getElementById("fileToUpload" + idnya);
            try {
                var namanya = elmnt.files[0].name;
                //alert(elmnt.files[0]['type'].toUpperCase());
                if (tipenya == 'image/*') {
                    if ((elmnt.files[0]['type'].toUpperCase() != 'IMAGE/JPG') && (elmnt.files[0]['type'].toUpperCase() != 'IMAGE/JPEG') && (elmnt.files[0]['type'].toUpperCase() != 'IMAGE/PNG') && (elmnt.files[0]['type'].toUpperCase() != 'IMAGE/GIF')) {
                        document.getElementById('suksesnya' + idnya).innerHTML = '*file harus format gambar';
                        return;
                    }
                }
                else {
                    if (elmnt.files[0]['type'] != tipenya) {
                        document.getElementById('suksesnya' + idnya).innerHTML = '*file harus format pdf';
                        return;
                    }
                }
            } catch (error) {
                document.getElementById('suksesnya' + idnya).innerHTML = '*pilih file terlebih dahulu';
                return;
            }
            var fd = new FormData();
            fd.append("fileToUpload", elmnt.files[0], elmnt.files[0].name);
            fd.append("folderToUpload", idnya);
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    "Content-Type": "form-data",
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            mainAPI.post("datakita-FilesUpload", fd, {
                onUploadProgress: function (uploadEvent) {
                    document.getElementById('persennya' + idnya).style.width = Math.round((uploadEvent.loaded / uploadEvent.total) * 100) + "%";
                    document.getElementById('tulisanpersen' + idnya).innerHTML = Math.round((uploadEvent.loaded / uploadEvent.total) * 100) + "%";
                }
            }).then(
                function (res) {
                    document.getElementById('suksesnya' + idnya).innerHTML = res.data.message;
                    if (res.data.filename != undefined) {
                        document.getElementById(idnya).value = res.data.filename;
                    }
                }
            ).catch(function (e) {
                document.getElementById('suksesnya' + idnya).innerHTML = res.data.message;
                document.getElementById(idnya).value = '';
                console.log(e);
            }
            )
        },
        clearfilepeta(idnya) {
            if (document.getElementById(idnya).value == '') {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'File Tidak Tersedia',
                });
                return false;
            }

            var fd = new FormData();
            fd.append("folderToUpload", idnya);
            fd.append("filenya", document.getElementById(idnya).value);
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    "Content-Type": "form-data",
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            mainAPI.post("datakitaparastapa-FilesDeleted", fd).then(
                Response => {
                    swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000
                    }).fire({
                        icon: 'warning',
                        title: Response.data.message,
                    });
                    document.getElementById(idnya).value == '';
                    this.filenya_data = '';
                }
            ).catch(function (error) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: error
                });
            }
            )

            document.getElementById('fileToUpload' + idnya).value = '';
            document.getElementById(idnya).value = '';
            document.getElementById('persennya' + idnya).style.width = "0%";
            document.getElementById('tulisanpersen' + idnya).innerHTML = "0%";
            document.getElementById('suksesnya' + idnya).innerHTML = '';
            return false;
        },
        async ambildatareff() {
            this.halamanloading = true;
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer DKMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2",
                },
            });
            var random = Math.random();
            await mainAPI.get("datakita-GetKecamatan?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    //alert(this.datapesan);
                    if (this.datapesan == 'data diketemukan') {
                        this.KECAMATAN = [];
                        this.dataKECAMATAN = [];
                        this.dataKECAMATAN = Response.data.content.data;
                        //alert(JSON.stringify(this.dataKECAMATAN));
                        this.dataKECAMATAN.forEach((item) => {
                            this.KECAMATAN.push({
                                label: item.NAMA,
                                code: this.dataKECAMATAN.indexOf(item) + 1,
                                tag: item.KODE,
                            });
                        });
                        this.KECAMATAN.unshift({
                            label: 'Pilih Salah Satu',
                            code: 0,
                            tag: '',
                        });
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                this.datapesan = '';
                this.KECAMATAN = [];
                this.dataKECAMATAN = [];
                console.log(error);
                this.halamanloading = false;
            });

            await mainAPI.get("datakita-GetMKelurahan?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    //alert(this.datapesan);
                    if (this.datapesan == 'data diketemukan') {
                        this.KELURAHAN = [];
                        this.dataKELURAHAN = [];
                        this.dataKELURAHAN = Response.data.content.data;
                        //alert(JSON.stringify(this.dataKELURAHAN));
                        this.dataKELURAHAN.forEach((item) => {
                            this.KELURAHAN.push({
                                label: item.NAMA,
                                code: this.dataKELURAHAN.indexOf(item) + 1,
                                tag: item.KODE,
                            });
                        });
                        this.KELURAHAN.unshift({
                            label: 'Pilih Salah Satu',
                            code: 0,
                            tag: '',
                        });
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                this.datapesan = '';
                this.KELURAHAN = [];
                this.dataKELURAHAN = [];
                console.log(error);
                this.halamanloading = false;
            });

        },
        async ambildataKELURAHAN(kode) {
            this.halamanloading = true;
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer DKMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2",
                },
            });
            var random = Math.random();
            await mainAPI.get("datakita-GetMKelurahan?random=" + random + "&kodekecamatan=" + kode).then(
                Response => {
                    this.datapesan = Response.data.message;
                    //alert(this.datapesan);
                    if (this.datapesan == 'data diketemukan') {
                        this.KELURAHAN = [];
                        this.dataKELURAHAN = [];
                        this.dataKELURAHAN = Response.data.content.data;
                        //alert(JSON.stringify(this.dataKELURAHAN));
                        this.dataKELURAHAN.forEach((item) => {
                            this.KELURAHAN.push({
                                label: item.NAMA,
                                code: this.dataKELURAHAN.indexOf(item) + 1,
                                tag: item.KODE,
                            });
                        });
                        this.KELURAHAN.unshift({
                            label: 'Pilih Salah Satu',
                            code: 0,
                            tag: '',
                        });
                        this.selectedKELURAHAN = '';
                        this.defaultSelectedKELURAHAN = {
                            label: 'Pilih Salah Satu',
                            code: 0,
                            tag: '',
                        };
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                this.datapesan = '';
                this.KELURAHAN = [];
                this.dataKELURAHAN = [];
                console.log(error);
                this.halamanloading = false;
            });
        },
        async AmbilDataSingel() {
            this.halamanloading = true;
            var mainAPIDataSingel = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            await mainAPIDataSingel.get("datakita_lpju-GetLPJUByID?id=" + this.secretencData + "&random=" + random).then(
                Response => {
                    this.NAMA = Response.data.content.data[0].NAMA;
                    this.IDLPJU = Response.data.content.data[0].IDLPJU;
                    this.ID_APP = Response.data.content.data[0].ID_APP;
                    this.selectedLEGAL = Response.data.content.data[0].LEGAL;
                    var codelegal = '0';
                    if (this.selectedLEGAL == 'TIDAK TERMETER') {
                        codelegal = '1';
                    }
                    else {
                        codelegal = '2';
                    }
                    this.defaultSelectedLEGAL = {
                        code: codelegal,
                        label: Response.data.content.data[0].LEGAL,
                        tag: Response.data.content.data[0].LEGAL,
                    }

                    this.NO_TIANG_P = Response.data.content.data[0].NO_TIANG_P;
                    this.TAHUN = Response.data.content.data[0].TAHUN;
                    this.LATITUDE = Response.data.content.data[0].LATITUDE;
                    this.LONGITUDE = Response.data.content.data[0].LONGITUDE;
                    this.JENIS = Response.data.content.data[0].JENIS;
                    this.TIPELAMPU = Response.data.content.data[0].TIPELAMPU;
                    this.WATT = Response.data.content.data[0].WATT;
                    this.RUMAHLAMPU = Response.data.content.data[0].RUMAHLAMPU;

                    this.selectedKONDISI_LP = Response.data.content.data[0].KONDISI_LP;
                    var codeKONDISI_LP = '0';
                    if (this.selectedKONDISI_LP == 'BAIK') {
                        codeKONDISI_LP = '1';
                    }
                    else if (this.selectedKONDISI_LP == 'SEDANG') {
                        codeKONDISI_LP = '2';
                    }
                    else if (this.selectedKONDISI_LP == 'RUSAK') {
                        codeKONDISI_LP = '3';
                    }
                    else {
                        codeKONDISI_LP = '4';
                    }

                    this.defaultSelectedKONDISI_LP = {
                        code: codeKONDISI_LP,
                        label: Response.data.content.data[0].KONDISI_LP,
                        tag: Response.data.content.data[0].KONDISI_LP,
                    };

                    this.PANJANG = Response.data.content.data[0].PANJANG;
                    this.selectedJALAN = Response.data.content.data[0].JALAN;
                    var codeJALAN = '0';
                    if (this.selectedJALAN == 'NASIONAL') {
                        codeJALAN = '1';
                    }
                    else if (this.selectedJALAN == 'PROVINSI') {
                        codeJALAN = '2';
                    }
                    else if (this.selectedJALAN == 'KABUPATEN') {
                        codeJALAN = '3';
                    }
                    else if (this.selectedJALAN == 'DESA') {
                        codeJALAN = '4';
                    }
                    else {
                        codeJALAN = '5';
                    }

                    this.defaultSelectedJALAN = {
                        code: codeJALAN,
                        label: Response.data.content.data[0].JALAN,
                        tag: Response.data.content.data[0].JALAN,
                    };


                    this.NAMA_JALAN = Response.data.content.data[0].NAMA_JALAN;
                    this.selectedKONDISI = Response.data.content.data[0].KONDISI;
                    var codeKONDISI = '0';
                    if (this.selectedKONDISI == 'BAIK') {
                        codeKONDISI = '1';
                    }
                    else if (this.selectedKONDISI == 'SEDANG') {
                        codeKONDISI = '2';
                    }
                    else {
                        codeKONDISI = '3';
                    }

                    this.defaultSelectedKONDISI = {
                        code: codeKONDISI,
                        label: Response.data.content.data[0].KONDISI,
                        tag: Response.data.content.data[0].KONDISI,
                    };

                    this.selectedKEWENANGAN = Response.data.content.data[0].KEWENANGAN;
                    var codeKEWENANGAN = '0';
                    if (this.selectedKEWENANGAN == 'NASIONAL') {
                        codeKEWENANGAN = '1';
                    }
                    else if (this.selectedKEWENANGAN == 'PROVINSI') {
                        codeKEWENANGAN = '2';
                    }
                    else if (this.selectedKEWENANGAN == 'KABUPATEN') {
                        codeKEWENANGAN = '3';
                    }
                    else if (this.selectedKEWENANGAN == 'DESA') {
                        codeKEWENANGAN = '4';
                    }
                    else {
                        codeKEWENANGAN = '5';
                    }

                    this.defaultSelectedKEWENANGAN = {
                        code: codeKEWENANGAN,
                        label: Response.data.content.data[0].KEWENANGAN,
                        tag: Response.data.content.data[0].KEWENANGAN,
                    };

                    this.selectedKECAMATAN = Response.data.content.data[0].KECAMATAN;
                    this.defaultSelectedKECAMATAN = {
                        code: Response.data.content.data[0].KECAMATAN,
                        label: Response.data.content.data[0].NAMAKECAMATAN,
                        tag: Response.data.content.data[0].KECAMATAN,
                    };

                    this.selectedKELURAHAN = Response.data.content.data[0].KELURAHAN;
                    this.defaultSelectedKELURAHAN = {
                        code: Response.data.content.data[0].KELURAHAN,
                        label: Response.data.content.data[0].NAMAKELURAHAN,
                        tag: Response.data.content.data[0].KELURAHAN,
                    };

                    this.filenya_data = Response.data.content.data[0].filenya_data;
                    document.getElementById('filenya_data').value = Response.data.content.data[0].filenya_data;
                    this.KETERANGAN = Response.data.content.data[0].KETERANGAN;
                    this.halamanloading = false;
                }
            ).catch(error => {
                swal.fire('Peringatan', error, 'error');
                this.halamanloading = false;
                return false;
            })
        },
        validasidata() {
            if (document.getElementById('NAMA').value == '') {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Isikan Data',
                });
                document.getElementById('NAMA').focus();
                return false;
            }
            if (this.selectedLEGAL == '' || this.selectedLEGAL == null || this.selectedLEGAL == undefined) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Pilih Jenis Legalitas',
                });
                return false;
            }
            if (document.getElementById('TAHUN').value == '') {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Isikan Data',
                });
                document.getElementById('TAHUN').focus();
                return false;
            }
            if (document.getElementById('LATITUDE').value == '') {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Isikan Data',
                });
                document.getElementById('LATITUDE').focus();
                return false;
            }
            if (document.getElementById('LONGITUDE').value == '') {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Isikan Data',
                });
                document.getElementById('LONGITUDE').focus();
                return false;
            }
            if (document.getElementById('WATT').value == '') {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Isikan Data',
                });
                document.getElementById('WATT').focus();
                return false;
            }
            if (this.selectedKONDISI_LP == '' || this.selectedKONDISI_LP == null || this.selectedKONDISI_LP == undefined) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Pilih Kondisi Lampu',
                });
                return false;
            }
            if (document.getElementById('PANJANG').value == '') {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Isikan Data',
                });
                document.getElementById('PANJANG').focus();
                return false;
            }
            if (this.selectedJALAN == '' || this.selectedJALAN == null || this.selectedJALAN == undefined) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Pilih Kewenangan Jalan LPJU',
                });
                return false;
            }
            if (document.getElementById('NAMA_JALAN').value == '') {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Isikan Data',
                });
                document.getElementById('NAMA_JALAN').focus();
                return false;
            }
            if (this.selectedKONDISI == '' || this.selectedKONDISI == null || this.selectedKONDISI == undefined) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Pilih Kondisi LPJU',
                });
                return false;
            }
            if (this.selectedKEWENANGAN == '' || this.selectedKEWENANGAN == null || this.selectedKEWENANGAN == undefined) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Pilih Kewenangan LPJU',
                });
                return false;
            }
            if (this.selectedKECAMATAN == '' || this.selectedKECAMATAN == null || this.selectedKECAMATAN == undefined) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Pilih Kecamatan LPJU',
                });
                return false;
            }
            if (this.selectedKELURAHAN == '' || this.selectedKELURAHAN == null || this.selectedKELURAHAN == undefined) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Pilih Kelurahan LPJU',
                });
                return false;
            }
            if (document.getElementById('filenya_data').value == '') {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Isikan Gambar',
                });
                return false;
            }
            this.validasi = true;
            return;
        },
        simpan() {
            this.validasidata();
            if (this.validasi == false) {
                return false;
            }
            var fd = new FormData();
            fd.append("NAMA", this.NAMA);
            fd.append("IDLPJU", this.IDLPJU);
            fd.append("ID_APP", this.ID_APP);
            fd.append("LEGAL", this.selectedLEGAL);
            fd.append("NO_TIANG_P", this.NO_TIANG_P);
            fd.append("TAHUN", this.TAHUN);
            fd.append("LATITUDE", this.LATITUDE);
            fd.append("LONGITUDE", this.LONGITUDE);
            fd.append("JENIS", this.JENIS);
            fd.append("TIPELAMPU", this.TIPELAMPU);
            fd.append("WATT", this.WATT);
            fd.append("RUMAHLAMPU", this.RUMAHLAMPU);
            fd.append("KONDISI_LP", this.selectedKONDISI_LP);
            fd.append("PANJANG", this.PANJANG);
            fd.append("JALAN", this.selectedJALAN);
            fd.append("NAMA_JALAN", this.NAMA_JALAN);
            fd.append("KONDISI", this.selectedKONDISI);
            fd.append("KEWENANGAN", this.selectedKEWENANGAN);
            fd.append("KECAMATAN", this.selectedKECAMATAN);
            fd.append("KELURAHAN", this.selectedKELURAHAN);
            fd.append("PROVINSI", "JAWA TENGAH");
            fd.append("KAB_KOTA", "SEMARANG");
            fd.append("KETERANGAN", this.KETERANGAN);
            fd.append("filenya_data", document.getElementById('filenya_data').value);
            fd.append("createdby", JSON.parse(localStorage.getItem("usistem")));

            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    "Content-Type": "form-data",
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            mainAPI.post("datakita_lpju-AddLPJU", fd).then(
                Response => {
                    if (Response.data.response == 'error') {
                        swal.fire('Peringatan', Response.data.message, 'error');
                    }
                    else {
                        swal.fire('Informasi', 'Data telah tersimpan..', 'success').then(function () {
                            window.location.replace("/LPJUAdmin");
                        });
                    }
                }
            ).catch(function (error) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: error
                });
            }
            )
        },
        update() {
            this.validasidata();
            if (this.validasi == false) {
                return false;
            }
            var fd = new FormData();
            fd.append("NAMA", this.NAMA);
            fd.append("IDLPJU", this.IDLPJU);
            fd.append("ID_APP", this.ID_APP);
            fd.append("LEGAL", this.selectedLEGAL);
            fd.append("NO_TIANG_P", this.NO_TIANG_P);
            fd.append("TAHUN", this.TAHUN);
            fd.append("LATITUDE", this.LATITUDE);
            fd.append("LONGITUDE", this.LONGITUDE);
            fd.append("JENIS", this.JENIS);
            fd.append("TIPELAMPU", this.TIPELAMPU);
            fd.append("WATT", this.WATT);
            fd.append("RUMAHLAMPU", this.RUMAHLAMPU);
            fd.append("KONDISI_LP", this.selectedKONDISI_LP);
            fd.append("PANJANG", this.PANJANG);
            fd.append("JALAN", this.selectedJALAN);
            fd.append("NAMA_JALAN", this.NAMA_JALAN);
            fd.append("KONDISI", this.selectedKONDISI);
            fd.append("KEWENANGAN", this.selectedKEWENANGAN);
            fd.append("KECAMATAN", this.selectedKECAMATAN);
            fd.append("KELURAHAN", this.selectedKELURAHAN);
            fd.append("PROVINSI", "JAWA TENGAH");
            fd.append("KAB_KOTA", "SEMARANG");
            fd.append("KETERANGAN", this.KETERANGAN);
            fd.append("filenya_data", document.getElementById('filenya_data').value);
            fd.append("updatedby", JSON.parse(localStorage.getItem("usistem")));
            fd.append("id", this.secretencData);
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    "Content-Type": "form-data",
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            mainAPI.post("datakita_lpju-UpdateLPJU", fd).then(
                Response => {
                    if (Response.data.response == 'error') {
                        swal.fire('Peringatan', Response.data.message, 'error');
                    }
                    else {
                        swal.fire('Informasi', 'Data telah terupdate..', 'success').then(function () {
                            window.location.replace("/LPJUAdmin");
                        });
                    }
                }
            ).catch(function (error) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: error
                });
            }
            )
            return false;
        },
    },
    mounted() {
        this.getOtentifikasi();
        this.initialize();
        this.ambildatareff();
        if (this.secretencData != 'Tambah') {
            this.AmbilDataSingel();
        }
    }
}
</script>
<style></style>