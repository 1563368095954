<template>
  <div class="content" v-if="route.name == 'Beranda'">
    <Beranda/>
  </div>
  <div class="content" v-if="route.name == 'Login'">
    <Login/>
  </div>
  <div class="content" v-if="route.name == 'Aduan'">
    <Aduan/>
  </div>
  <div class="content" v-if="route.name == 'Publikasi'">
    <Publikasi/>
  </div>
  <div class="content" v-if="route.name == 'Ketersediaan'">
    <Ketersediaan/>
  </div>
  <div class="content" v-if="route.name == 'LPJU'">
    <LPJU/>
  </div>
  <div class="content" v-if="route.name == 'Kebutuhan'">
    <Kebutuhan/>
  </div>
  <div class="content" v-if="route.name == 'KontakKami'">
    <KontakKami/>
  </div>
  <div class="content" v-if="route.name == 'GIS'">
    <GIS/>
  </div>
  <div class="content" v-if="route.name == 'Dashboard'">
    <Dashboard/>
  </div>
  <div class="content" v-if="route.name == 'Slider'">
    <Slider/>
  </div>
  <div class="content" v-if="route.name == 'ProsesSlider'">
    <ProsesSlider/>
  </div>
  <div class="content" v-if="route.name == 'Dokumen'">
    <Dokumen/>
  </div>
  <div class="content" v-if="route.name == 'ProsesDokumen'">
    <ProsesDokumen/>
  </div>
  <div class="content" v-if="route.name == 'InfoBeranda'">
    <InfoBeranda/>
  </div>
  <div class="content" v-if="route.name == 'ProsesInfoBeranda'">
    <ProsesInfoBeranda/>
  </div>
  <div class="content" v-if="route.name == 'KategoriData'">
    <KategoriData/>
  </div>
  <div class="content" v-if="route.name == 'ProsesKategoriData'">
    <ProsesKategoriData/>
  </div>
  <div class="content" v-if="route.name == 'AduanAdmin'">
    <AduanAdmin/>
  </div>
  <div class="content" v-if="route.name == 'LayerPeta'">
    <LayerPeta/>
  </div>
  <div class="content" v-if="route.name == 'ProsesLayerPeta'">
    <ProsesLayerPeta/>
  </div>
  <div class="content" v-if="route.name == 'LPJUAdmin'">
    <LPJUAdmin/>
  </div>
  <div class="content" v-if="route.name == 'ProsesLPJUAdmin'">
    <ProsesLPJUAdmin/>
  </div>
</template>

<script>
import Beranda from './components/views/Publik/Beranda.vue';
import Login from './components/views/Publik/Login.vue';
import Aduan from './components/views/Publik/Aduan.vue';
import Publikasi from './components/views/Publik/Publikasi.vue';
import KontakKami from './components/views/Publik/KontakKami.vue';
import Ketersediaan from './components/views/Publik/Ketersediaan.vue';
import LPJU from './components/views/Publik/LPJU.vue';
import Kebutuhan from './components/views/Publik/Kebutuhan.vue';
import GIS from './components/views/Publik/GIS.vue';
import Dashboard from './components/views/Dashboard/Dashboard.vue';
import Slider from './components/views/Backoffice/Slider/Slider.vue';
import ProsesSlider from './components/views/Backoffice/Slider/ProsesSlider.vue';
import Dokumen from './components/views/Backoffice/Dokumen/Dokumen.vue';
import ProsesDokumen from './components/views/Backoffice/Dokumen/ProsesDokumen.vue';
import InfoBeranda from './components/views/Backoffice/InfoBeranda/InfoBeranda.vue';
import ProsesInfoBeranda from './components/views/Backoffice/InfoBeranda/ProsesInfoBeranda.vue';
import KategoriData from './components/views/Backoffice/KategoriData/KategoriData.vue';
import ProsesKategoriData from './components/views/Backoffice/KategoriData/ProsesKategoriData.vue';
import AduanAdmin from './components/views/Backoffice/Aduan/Aduan.vue';
import LayerPeta from './components/views/Backoffice/LayerPeta/LayerPeta.vue';
import ProsesLayerPeta from './components/views/Backoffice/LayerPeta/ProsesLayerPeta.vue';
import LPJUAdmin from './components/views/Backoffice/LPJUAdmin/LPJUAdmin.vue';
import ProsesLPJUAdmin from './components/views/Backoffice/LPJUAdmin/ProsesLPJUAdmin.vue';

import { useRoute } from 'vue-router';
import { watch } from 'vue';

export default {
  name: 'App',
  components: {
    Beranda,Login,Aduan,Publikasi,KontakKami,Ketersediaan,LPJU,Kebutuhan,GIS,Dashboard
    ,Slider,ProsesSlider,Dokumen,ProsesDokumen,InfoBeranda,ProsesInfoBeranda
    ,KategoriData,ProsesKategoriData,AduanAdmin,LayerPeta,ProsesLayerPeta
    ,LPJUAdmin,ProsesLPJUAdmin
  },
  mounted() {
    document.title = "SiPEJALAN";
  },
  setup() {
    const route = useRoute()
    watch(() => route.name, () => {
    });

    return { route };
  }
}
</script>

<style></style>
