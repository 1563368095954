<template>
    <div class="wrapper">
        <NavBar />
        <Menu />
        <!-- Spinner Start -->
        <div id="spinner" v-if="halamanloading"
            class="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
            <div class="spinner-border text-danger" style="width: 3rem; height: 3rem;" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
        <!-- Spinner End -->
        <div class="content-wrapper py-5">
            <div class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-6 wow fadeInDown">
                            <h5>Data LPJU</h5>
                        </div>
                        <div class="col-sm-6 wow fadeInDown">
                            <ol class="breadcrumb float-sm-right">
                                <li class="breadcrumb-item"><a href="/Dashboard">Home </a></li>
                                <li class="breadcrumb-item active">Data LPJU</li>
                            </ol>
                        </div>
                    </div><!-- /.row -->
                </div><!-- /.container-fluid -->
            </div>
            <!-- /.content-header -->
            <div class="content">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-sm-12 wow fadeInDown">
                            <div class="card cardputih collapsed-card">
                                <div class="card-header border-transparent" data-card-widget="collapse"
                                    style="cursor: pointer;">
                                    <label>Filter Data Tabel</label>
                                    <div class="card-tools">
                                        <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                            <i class="fas fa-plus"></i>
                                        </button>
                                    </div>
                                </div>
                                <div class="card-body px-3">
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <label>Kecamatan</label>
                                            <v-select :options="KECAMATAN" :reduce="(label) => label.code" label="label"
                                                v-model="defaultSelectedKECAMATAN"
                                                @update:modelValue="onKECAMATANChange"
                                                style="background-color: #ffffff;"></v-select>
                                        </div>
                                        <div class="col-sm-6">
                                            <label>Kelurahan</label>
                                            <v-select :options="KELURAHAN" :reduce="(label) => label.code" label="label"
                                                v-model="defaultSelectedKELURAHAN"
                                                @update:modelValue="onKELURAHANChange"
                                                style="background-color: #ffffff;"></v-select>
                                        </div>
                                        <div class="col-sm-6">
                                            <label>Kewenangan LPJU</label>
                                            <v-select :options="KEWENANGAN" :reduce="(label) => label.code"
                                                label="label" v-model="defaultSelectedKEWENANGAN"
                                                @update:modelValue="onKEWENANGANChange"
                                                style="background-color: #ffffff;"></v-select>
                                        </div>
                                        <div class="col-sm-6">
                                            <label>Kondisi LPJU</label>
                                            <v-select :options="KONDISI" :reduce="(label) => label.code" label="label"
                                                v-model="defaultSelectedKONDISI" @update:modelValue="onKONDISIChange"
                                                style="background-color: #ffffff;"></v-select>
                                        </div>
                                        <div class="col-sm-6">
                                            <label>Jenis Legalitas</label>
                                            <v-select :options="LEGAL" :reduce="(label) => label.code" label="label"
                                                v-model="defaultSelectedLEGAL" @update:modelValue="onLEGALChange"
                                                style="background-color: #ffffff;"></v-select>
                                        </div>
                                        <div class="col-sm-6">
                                            <label>Nomor Tiang</label>
                                            <input type="text" v-model="carikata" class="form-control form-control-sm"
                                                placeholder="Masukkan Kata Kunci Nomor Tiang" @keyup="ambildata()">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 wow fadeInDown">
                            <div class="card cardputih">
                                <div class="card-header border-transparent">
                                    <div class="col-sm-12 float-left pb-2 px-3 wow fadeInDown">
                                        <button type="button" class="btn btn-danger3 btn-sm text-white lebar2"
                                            @click="prosesdata('Tambah')" data-toggle="tooltip" data-placement="left"
                                            title="Tambah data..">
                                            <i class="fas fa-plus"></i> Tambah
                                        </button>
                                        <span style="float: right;">
                                            Jumlah Data {{ G_numFormat(jumlahdata) }}, &nbsp;
                                            <span>Halaman ke: </span>
                                            <select v-model="halaman" @change="onChangeHalaman($event)">
                                                <option v-for="index in jumlahhalaman" :key="index">
                                                    {{ index }}</option>
                                            </select>
                                            <span> dari </span> {{ jumlahhalaman }}
                                        </span>
                                    </div>
                                    <div class="card-tools">
                                        <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                            <i class="fas fa-minus"></i>
                                        </button>
                                    </div>
                                </div>
                                <div class="card-body p-0">
                                    <div class="table-responsive table-wrapper">
                                        <center>
                                            <div class="spinner-border text-danger text-center"
                                                style="width: 3rem; height: 3rem;" role="status"
                                                v-show="datamaster.length == 0">
                                                <span class="sr-only">Loading...</span>
                                            </div>
                                            <span v-show="datamaster.length == 0">Loading Data .....</span>
                                        </center>
                                        <table class="table m-0" id="tabeldata">
                                            <thead>
                                                <tr>
                                                    <th style="width: 50px;">No</th>
                                                    <th style="width: 100px;">No Tiang</th>
                                                    <th style="width: 100px;">Legalitas</th>
                                                    <th style="width: 150px;">Koordinat</th>
                                                    <th style="width: 150px;">Lokasi</th>
                                                    <th style="width: 100px;">Jenis</th>
                                                    <th style="width: 100px;">Kewenangan</th>
                                                    <th style="width: 150px;">Jalan</th>
                                                    <th style="width: 110px;">Opsi</th>
                                                </tr>
                                            </thead>
                                            <template v-for="(datalist, urutlist) in datamaster" :key="urutlist">
                                                <tbody>
                                                    <tr>
                                                        <td>{{ (urutlist + 1) + (20 * halaman) - 20 }}</td>
                                                        <td>
                                                            {{ datalist.NO_TIANG_P }}
                                                        </td>
                                                        <td>
                                                            {{ datalist.LEGAL }}
                                                        </td>
                                                        <td class="text-center">
                                                            {{ datalist.LATITUDE }}, {{ datalist.LONGITUDE }}
                                                        </td>
                                                        <td class="text-justify">
                                                            Desa {{ datalist.NAMAKELURAHAN }}, Kecamatan {{
                                                                datalist.NAMAKECAMATAN }}
                                                        </td>
                                                        <td class="text-center">
                                                            {{ datalist.JENIS }}
                                                        </td>
                                                        <td class="text-center">
                                                            {{ datalist.KEWENANGAN }}
                                                        </td>
                                                        <td class="text-center">
                                                            {{ datalist.NAMA_JALAN }}
                                                        </td>
                                                        <td class="text-center">
                                                            <button type="button"
                                                                class="btn btn-primary3 btn-sm lebar2 text-white"
                                                                @click="prosesdata(datalist.id)" data-toggle="tooltip"
                                                                data-placement="left" title="Edit data.."><i
                                                                    class="fas fa-edit"></i> Edit Data</button>
                                                            <button type="button"
                                                                class="btn btn-info3 btn-sm lebar2 text-white"
                                                                @click="hapus(datalist.id)" data-toggle="tooltip"
                                                                data-placement="left" title="Hapus data.."><i
                                                                    class="fas fa-trash-alt"></i> Hapus Data</button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </template>
                                            <tbody v-if="(datapesan == 'data kosong')">
                                                <tr>
                                                    <td colspan="4">Data masih kosong</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class="card-footer p-3">

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import $ from 'jquery';
import axios from 'axios';
import vSelect from 'vue-select';
import swal from 'sweetalert2';
import CryptoJS from 'crypto-js';
import Footer from "../../componen/Footer.vue";
import NavBar from "../../componen/NavBar.vue";
import Menu from "../../componen/Menu.vue";

import VueApexCharts from "vue3-apexcharts";
window.Apex.chart = { fontFamily: "Poppins-SemiBold, Arial, sans-serif" };
export default {
    components: {
        Footer, NavBar, Menu, vSelect, swal
    },
    data() {
        return {
            halamanloading: true,
            iduser: JSON.parse(localStorage.getItem("usistem")),
            leveluser: JSON.parse(localStorage.getItem("lsistem")),
            namauser: JSON.parse(localStorage.getItem("nmusistem")),
            datapesan: '',
            datamaster: [],
            carikata: '',
            jumlahdata: 0,
            jumlahhalaman: 0,
            mulai: 0,
            halaman: 1,
            folder_foto: process.env.VUE_APP_URL_API + 'datakita_lpju-Gambar?f=',
            KELURAHAN: [],
            dataKELURAHAN: [],
            defaultSelectedKELURAHAN: {
                code: 0,
                label: 'Semua Data',
                tag: '',
            },
            selectedKELURAHAN: '',

            KECAMATAN: [],
            dataKECAMATAN: [],
            defaultSelectedKECAMATAN: {
                code: 0,
                label: 'Semua Data',
                tag: '',
            },
            selectedKECAMATAN: '',

            LEGAL: [
                {
                    code: 0,
                    label: 'Semua Data',
                    tag: '',
                }, {
                    code: 1,
                    label: 'TIDAK TERMETER',
                    tag: 'TIDAK TERMETER',
                },
                {
                    code: 2,
                    label: 'TERMETER',
                    tag: 'TERMETER',
                },
            ],
            dataLEGAL: [],
            defaultSelectedLEGAL: {
                code: 0,
                label: 'Semua Data',
                tag: '',
            },
            selectedLEGAL: '',

            KEWENANGAN: [
                {
                    code: 0,
                    label: 'Semua Data',
                    tag: '',
                }, {
                    code: 1,
                    label: 'NASIONAL',
                    tag: 'NASIONAL',
                },
                {
                    code: 2,
                    label: 'PROVINSI',
                    tag: 'PROVINSI',
                },
                {
                    code: 3,
                    label: 'KABUPATEN',
                    tag: 'KABUPATEN',
                },
                {
                    code: 4,
                    label: 'DESA',
                    tag: 'DESA',
                },
                {
                    code: 5,
                    label: 'LINGKUNGAN',
                    tag: 'LINGKUNGAN',
                },
            ],
            dataKEWENANGAN: [],
            defaultSelectedKEWENANGAN: {
                code: 0,
                label: 'Semua Data',
                tag: '',
            },
            selectedKEWENANGAN: '',

            KONDISI: [
                {
                    code: 0,
                    label: 'Semua Data',
                    tag: '',
                }, {
                    code: 1,
                    label: 'BAIK',
                    tag: 'BAIK',
                },
                {
                    code: 2,
                    label: 'SEDANG',
                    tag: 'SEDANG',
                },
                {
                    code: 3,
                    label: 'RUSAK',
                    tag: 'RUSAK',
                },
            ],
            dataKONDISI: [],
            defaultSelectedKONDISI: {
                code: 0,
                label: 'Semua Data',
                tag: '',
            },
            selectedKONDISI: '',
        }
    },
    computed: {
        filteredKataData() {
            const filteredWorkers = this.carikata === ""
                ? this.datamaster
                : this.datamaster.filter(wo => Object.values(wo).join("").toLowerCase().indexOf(this.carikata.toLowerCase()) !== -1);
            return filteredWorkers;
        },
        JumlahfilteredKataData() {
            var jumlah = 0;
            try {
                if (this.filteredKataData === undefined) {
                    jumlah = 0;
                } else {
                    jumlah = this.filteredKataData.length;
                }
            }
            catch {
                jumlah = 0;
            }
            return jumlah;
        },
    },
    methods: {
        onKONDISIChange(a) {
            try {
                this.selectedKONDISI = this.KONDISI[a].tag;
            }
            catch (err) {
                return;
            }
            this.ambildata();
        },
        onKEWENANGANChange(a) {
            try {
                this.selectedKEWENANGAN = this.KEWENANGAN[a].tag;
            }
            catch (err) {
                return;
            }
            this.ambildata();
        },
        onLEGALChange(a) {
            try {
                this.selectedLEGAL = this.LEGAL[a].tag;
            }
            catch (err) {
                return;
            }
            this.ambildata();
        },
        onKECAMATANChange(a) {
            try {
                this.selectedKECAMATAN = this.KECAMATAN[a].tag;
            }
            catch (err) {
                return;
            }
            this.ambildataKELURAHAN(this.selectedKECAMATAN);
        },
        onKELURAHANChange(a) {
            try {
                this.selectedKELURAHAN = this.KELURAHAN[a].tag;
            }
            catch (err) {
                return;
            }
            this.ambildata();
        },
        async ambildatareff() {
            this.halamanloading = true;
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer DKMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2",
                },
            });
            var random = Math.random();
            await mainAPI.get("datakita-GetKecamatan?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    //alert(this.datapesan);
                    if (this.datapesan == 'data diketemukan') {
                        this.KECAMATAN = [];
                        this.dataKECAMATAN = [];
                        this.dataKECAMATAN = Response.data.content.data;
                        //alert(JSON.stringify(this.dataKECAMATAN));
                        this.dataKECAMATAN.forEach((item) => {
                            this.KECAMATAN.push({
                                label: item.NAMA,
                                code: this.dataKECAMATAN.indexOf(item) + 1,
                                tag: item.KODE,
                            });
                        });
                        this.KECAMATAN.unshift({
                            label: 'Semua Data',
                            code: 0,
                            tag: '',
                        });
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                this.datapesan = '';
                this.KECAMATAN = [];
                this.dataKECAMATAN = [];
                console.log(error);
                this.halamanloading = false;
            });

            await mainAPI.get("datakita-GetMKelurahan?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    //alert(this.datapesan);
                    if (this.datapesan == 'data diketemukan') {
                        this.KELURAHAN = [];
                        this.dataKELURAHAN = [];
                        this.dataKELURAHAN = Response.data.content.data;
                        //alert(JSON.stringify(this.dataKELURAHAN));
                        this.dataKELURAHAN.forEach((item) => {
                            this.KELURAHAN.push({
                                label: item.NAMA,
                                code: this.dataKELURAHAN.indexOf(item) + 1,
                                tag: item.KODE,
                            });
                        });
                        this.KELURAHAN.unshift({
                            label: 'Semua Data',
                            code: 0,
                            tag: '',
                        });
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                this.datapesan = '';
                this.KELURAHAN = [];
                this.dataKELURAHAN = [];
                console.log(error);
                this.halamanloading = false;
            });

        },
        async ambildataKELURAHAN(kode) {
            this.halamanloading = true;
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer DKMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2",
                },
            });
            var random = Math.random();
            await mainAPI.get("datakita-GetMKelurahan?random=" + random + "&kodekecamatan=" + kode).then(
                Response => {
                    this.datapesan = Response.data.message;
                    //alert(this.datapesan);
                    if (this.datapesan == 'data diketemukan') {
                        this.KELURAHAN = [];
                        this.dataKELURAHAN = [];
                        this.dataKELURAHAN = Response.data.content.data;
                        //alert(JSON.stringify(this.dataKELURAHAN));
                        this.dataKELURAHAN.forEach((item) => {
                            this.KELURAHAN.push({
                                label: item.NAMA,
                                code: this.dataKELURAHAN.indexOf(item) + 1,
                                tag: item.KODE,
                            });
                        });
                        this.KELURAHAN.unshift({
                            label: 'Semua Data',
                            code: 0,
                            tag: '',
                        });
                        this.selectedKELURAHAN = '';
                        this.defaultSelectedKELURAHAN = {
                            label: 'Semua Data',
                            code: 0,
                            tag: '',
                        };
                    }
                    this.ambildata();
                    this.halamanloading = false;
                }
            ).catch(error => {
                this.datapesan = '';
                this.KELURAHAN = [];
                this.dataKELURAHAN = [];
                console.log(error);
                this.halamanloading = false;
            });
        },
        getOtentifikasi() {
            var time = Date.parse(localStorage.getItem("tsistem"));
            var timeakhir = time + (3 * 60 * 60 * 1000);
            var timesekarang = Date.parse(new Date());
            if (timesekarang > timeakhir) {
                this.ClearlocalStorage();
            }
        },
        async ambildata() {
            this.halamanloading = true;
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            await mainAPI.get("datakita_lpju-GetLPJU?random=" + random + "&kecamatan=" + this.selectedKECAMATAN
                + "&kelurahan=" + this.selectedKELURAHAN + "&legal=" + this.selectedLEGAL + "&notiang=" + this.carikata
                + "&kewenangan=" + this.selectedKEWENANGAN + "&kondisi=" + this.selectedKONDISI
            ).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datamaster = [];
                        this.datamaster = Response.data.content.data;
                        this.jumlahdata = Response.data.content.jumlahdata;
                        this.jumlahhalaman = Response.data.content.jumlahhalaman;
                        this.mulai = Response.data.content.mulai;
                        if (this.mulai == 0) {
                            this.halaman = 1;
                        }
                        else {
                            this.halaman = (this.mulai / 20) + 1;
                        }
                    }
                    else {
                        this.datamaster = [];
                        this.jumlahdata = 0;
                        this.jumlahhalaman = 0;
                        this.mulai = 0;
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                this.datamaster = [];
                this.jumlahdata = 0;
                this.jumlahhalaman = 0;
                this.mulai = 0;
                console.log(error);
                this.ClearlocalStorage();
                this.halamanloading = false;
            });
        },
        onChangeHalaman(event) {
            this.pindahhalaman(event.target.value);
        },
        async pindahhalaman(halamannya) {
            this.halamanloading = true;
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            await mainAPI.get("datakita_lpju-GetLPJU?random=" + random + "&halaman=" + halamannya + "&kecamatan=" + this.selectedKECAMATAN
                + "&kelurahan=" + this.selectedKELURAHAN + "&legal=" + this.selectedLEGAL + "&notiang=" + this.carikata
                + "&kewenangan=" + this.selectedKEWENANGAN + "&kondisi=" + this.selectedKONDISI).then(
                    Response => {
                        this.datapesan = Response.data.message;
                        //alert(this.datapesan);
                        if (this.datapesan == 'data diketemukan') {
                            this.datamaster = [];
                            this.datamaster = Response.data.content.data;
                            this.jumlahdata = Response.data.content.jumlahdata;
                            this.jumlahhalaman = Response.data.content.jumlahhalaman;
                            this.mulai = Response.data.content.mulai;
                            if (this.mulai == 0) {
                                this.halaman = 1;
                            }
                            else {
                                this.halaman = (this.mulai / 20) + 1;
                            }
                        }
                        else {
                            this.datamaster = [];
                            this.jumlahdata = 0;
                            this.jumlahhalaman = 0;
                            this.mulai = 0;
                        }
                        this.halamanloading = false;
                    }
                ).catch(error => {
                    //console.log(error);
                    //this.ClearlocalStorage();
                    this.halamanloading = false;
                });
        },
        prosesdata(kode) {
            var bytes = encodeURIComponent(CryptoJS.AES.encrypt(kode, 'oriproMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2').toString());
            window.location.replace("/ProsesLPJUAdmin/" + bytes);
        },
        hapus(kode) {
            swal.fire({
                title: "Konfirmasi",
                text: "Yakin Hendak Hapus Data?",
                icon: "warning",
                showCancelButton: true,
                cancelButtonText: "Batal",
                confirmButtonColor: "#0092cf",
                cancelButtonColor: "#ffaa05",
                confirmButtonText: "Hapus"
            }).then((result) => {
                if (result.isConfirmed) {
                    const data = { kode: kode, deletedby: JSON.parse(localStorage.getItem("usistem")) }
                    const mainAPI = axios.create({
                        baseURL: process.env.VUE_APP_URL_API,
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                            "Content-Type": "application/x-www-form-urlencoded",
                        },
                    });
                    mainAPI.post("datakita_lpju-DeleteLPJU", data).then(
                        Response => {
                            if (Response.data.response == 'error') {
                                swal.fire('Peringatan', Response.data.message, 'error');
                            }
                            else {
                                swal.fire('Informasi', 'Data telah dihapus..', 'success').then(function () {
                                    window.location.replace("/LPJUAdmin");
                                });
                            }
                        }
                    ).catch(
                        error => {
                            swal.fire('Peringatan', 'Terjadi Kesalahan Format Data', 'error');
                        }
                    )

                }
            });
            return false;
        },
    },
    mounted() {
        this.getOtentifikasi();
        this.ambildatareff();
        this.ambildata();
    }
}
</script>
<style></style>