<template>
    <!-- Copyright Start -->
    <div class="container-fluid copyright py-4">
        <div class="container">
            <div class="row g-6 align-items-center">
                <div class="col-md-4 text-center text-md-start mb-md-0">
                    <span class="text-white">
                        <a href="#" class="merahmaroon">
                            <i class="fas fa-copyright text-light me-2"></i> SiPEJALAN
                        </a>
                        {{ tahun }} All right reserved.
                    </span>
                </div>
                <div class="col-md-12 text-center text-md-start mb-md-0 text-white">
                    Designed By <a href="https://www.instagram.com/dishub_kabsemarang/?hl=id" target="_blank"><span class="merahmaroon">DISHUB KAB. SEMARANG</span> </a>,
                    Contributed By
                    <a href="https://www.instagram.com/jdc_consultan/?hl=id" target="_blank"><span class="merahmaroon">J.D. Technology</span></a>
                </div>
            </div>
        </div>
    </div>
    <!-- Copyright End -->
</template>

<script>
import axios from 'axios';
import $ from 'jquery';
import swal from 'sweetalert2';

export default {
    components: {
        swal
    },
    data() {
        return {
            tahun: new Date().getFullYear(),
        };
    },
    methods: {
    },
    mounted() {

    },
}
</script>

<style></style>